import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
const shuffle = a => {
  var j, x, i
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1))
    x = a[i]
    a[i] = a[j]
    a[j] = x
  }
  return a
}
const Friends = ({ data, location }) => {
  const sites = shuffle(
    data.allMysqlSites.edges.filter(node => node.node.is_active === "y")
  )
  return (
    <Layout location={location}>
      <SEO title="Home" />
      <div className="thumbs">
        {sites.map((site, i) => {
          if (i < 2) {
            return (
              <div className="thumb x2" key={`${site.node.mysqlId}msid`}>
                <div className="th">
                  <Link to={`/${site.node.site_url}/river`}>
                    <Image
                      alt={site.node.site_name}
                      filename={`${site.node.site_url}.jpg`}
                    />
                    <div className="inform">
                      <div className="column">
                        {site.node.sitestat.likes} <span>likes</span>
                      </div>
                      <div className="column">
                        {site.node.sitestat.videos} <span>videos</span>
                      </div>
                    </div>
                  </Link>
                  <div className="field_more">
                    <Link
                      to={`/${site.node.site_url}/river`}
                      className="button_more"
                    >
                      <i className="icon-snapchat" />
                      <span>Get my full library of videos + private snap!</span>
                    </Link>
                  </div>
                </div>
              </div>
            )
          }
          return (
            <div className="thumb x3" key={`${site.node.mysqlId}msid`}>
              <div className="th">
                <Link to={`/${site.node.site_url}/river`}>
                  <Image
                    alt={site.node.site_name}
                    filename={`${site.node.site_url}.jpg`}
                  />
                </Link>
              </div>
            </div>
          )
        })}
      </div>
      <div className="bottom_more">
        much <strong>more</strong> coming soon...
      </div>
    </Layout>
  )
}

// export const pageQuery = graphql`
//   query IndexQuery {
//     allFile(filter: { relativeDirectory: { eq: "friends-frontpage" } }) {
//       edges {
//         node {
//           childImageSharp {
//             fluid(maxWidth: 938) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//       }
//     }
//   }
// `

export const pageQuery = graphql`
  query MyFriendQuery {
    allMysqlSites(filter: { show_tour: { eq: "y" } }) {
      edges {
        node {
          free_snapchat
          site_name
          is_active
          site_url
          mysqlId
          sitestat {
            likes
            videos
          }
        }
      }
    }
  }
`

export default Friends
